import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Location } from '@reach/router';
import {Helmet} from "react-helmet";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

class CheckOut extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }

    render() {

        const { location } = this.props;
        function getParameterByName(name, url) {
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

        var email = getParameterByName("email",location.href);
        var cc = getParameterByName("cc",location.href);
        var subject = getParameterByName("subject",location.href);
        var body = getParameterByName("body",location.href);
        var contenido = "0; url=mailto:"+email+"?cc="+cc+"&subject="+subject+"&body="+body

        trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Email redireccion",
            // string - required - Type of interaction (e.g. 'play')
            action: "Email redireccion",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: email
        })
        return (
            <Helmet defer={false} defaultTitle="Equipzilla">
                <meta http-equiv="refresh" content={contenido}/>
            </Helmet>
        )
    }
}

export default props => (
    <Location>
        {locationProps => <CheckOut {...locationProps} {...props} />}
    </Location>
);
